import React from "react"
import Image from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux"
import { useIntl } from "react-intl"

import { Layout, SEO } from "../components"
import { mq } from "../utils/media-query"
import { getCurrentPath } from "../redux/actions/pathAction"

const About = ({ location, getCurrentPath }) => {
  const intl = useIntl()
  const {
    data1: { story },
    data2: { goal },
    data3: { approach },
  } = useStaticQuery(graphql`
    query {
      data1: file(relativePath: { regex: "/our-story/" }) {
        story: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      data2: file(relativePath: { regex: "/our-goal/" }) {
        goal: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      data3: file(relativePath: { regex: "/our-approach/" }) {
        approach: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    getCurrentPath(location.pathname)
  })

  return (
    <Layout>
      <SEO title="About Us" />
      <div
        css={css`
          padding: 3rem;
          width: 90vw;
          margin: 0 auto;

          ${mq[1]} {
            display: grid;
          }
        `}
      >
        <SectionRight>
          <div className="section-wrap">
            <article className="text-wrap">
              <div className="title-wrap">
                <div className="underline"></div>
                <h2>{intl.formatMessage({ id: "about.section1" })}</h2>
              </div>
              <p>{intl.formatMessage({ id: "about.section1text" })}</p>
            </article>
            <Image
              fluid={story.fluid}
              css={css`
                max-height: 350px;
              `}
              imgStyle={{
                objectPosition: "center top",
                objectFit: "cover",
                borderRadius: "var(--border-radius-lg)",
              }}
            />
          </div>
        </SectionRight>
        <SectionLeft>
          <div className="section-wrap">
            <article className="text-wrap">
              <div className="title-wrap">
                <div className="underline"></div>
                <h2>{intl.formatMessage({ id: "about.section2" })}</h2>
              </div>
              <p>
                {intl.formatMessage({ id: "about.section2text1" })} <br />
                <br />
                {intl.formatMessage({ id: "about.section2text2" })}
              </p>
            </article>
            <Image
              fluid={goal.fluid}
              css={css`
                max-height: 350px;
              `}
              imgStyle={{
                objectPosition: "center 60%",
                objectFit: "cover",
                borderRadius: "var(--border-radius-lg)",
              }}
            />
          </div>
        </SectionLeft>
        <SectionRight>
          <div className="section-wrap">
            <article className="text-wrap">
              <div className="title-wrap">
                <div className="underline"></div>
                <h2>{intl.formatMessage({ id: "about.section3" })}</h2>
              </div>
              <p>
                {intl.formatMessage({ id: "about.section3text1" })}
                <br />
                <br />
                {intl.formatMessage({ id: "about.section3text2" })}
              </p>
              <Image
                fluid={approach.fluid}
                css={css`
                  height: 350px;
                `}
                imgStyle={{
                  objectFit: "cover",
                  borderRadius: "var(--border-radius-lg)",
                }}
              />
            </article>
          </div>
        </SectionRight>
      </div>
    </Layout>
  )
}

const SectionBasic = styled.section`
  text-align: center;
  margin-bottom: 5rem;

  * .title-wrap {
    display: grid;
    grid-template-columns: max-content;
    justify-content: center;
    /* background: var(--clr-neutral-200); */
    border-radius: var(--border-radius-lg);
    /* margin: 2rem 0; */
    padding: 1rem;
  }

  * .underline {
    width: 4rem;
    height: 0.15rem;
    margin-bottom: 0.5rem;
    background: var(--clr-primary-300);
    margin-right: auto;
    border-radius: 5%;
  }

  * p {
    color: var(--clr-primary-300);
    font-family: var(--ff-quaternary);
    line-height: 2;
    font-size: 1rem;
    text-align: left;

    margin: 1.5rem auto;
    box-shadow: var(--light-shadow);
    padding: 1.5rem;
    border-radius: var(--border-radius-md);
  }
  * h2 {
    color: var(--clr-primary-300);
    margin: 0;
  }
`

const SectionRight = styled(SectionBasic)``
const SectionLeft = styled(SectionBasic)``

export default connect(null, { getCurrentPath })(About)
